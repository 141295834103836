import React from "react"
import { graphql } from "gatsby"

import styled from "styled-components"

import Layout from "../components/Layout"
import { Link } from "gatsby"
import Image from "gatsby-image"

const Conatiner = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StaticImageContainer = styled.div`
  z-index: 0;
  position: relative;
  padding: 0px 1vmin;
  transition: 0.3s ease-in-out;

  cursor: pointer;

  // Extra small devices (portrait phones, less than 576px)
  width: 150px;
  height: 250px;
  font-size: 12px;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    width: 250px;
    height: 333px;
    font-size: 12px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 300px;
    height: 400px;
    font-size: 16px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 450px;
    height: 600px;
    font-size: 20px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    width: 500px;
    height: 750px;
    font-size: 24px;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  .description {
    z-index: 1;
    position: absolute;
    top: 27%;
    bottom: 0px;
    left: 0.5em;
    right: 0.5em;

    color: white;
    font-family: "Monument", sans-serif;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;

    transition: color 1s ease-in-out;
  }
`

const DynamicImageContainer = styled(StaticImageContainer)`
  &:hover {
    transform: scale(1.02);

    .description {
      color: #ffbc69;
    }
  }
`

export default ({ data }) => {
  return (
    <Layout
      title={
        <>
          BARSKA
          <br />
          PHOTO
          <br />
          STORIES
        </>
      }
    >
      <Conatiner>
        <Wrapper>
          <DynamicImageContainer onClick={() => {}}>
            <Link to="/documentary">
              <div className="description">DOCUMENTARY</div>
              <Image fluid={data.documentaryImg.childImageSharp.fluid}></Image>
            </Link>
          </DynamicImageContainer>
          <DynamicImageContainer>
            <Link to="/personal-branding">
              <div className="description">
                PERSONAL <br /> BRANDING
              </div>
              <Image
                fluid={data.personalBrandingImg.childImageSharp.fluid}
              ></Image>
            </Link>
          </DynamicImageContainer>
        </Wrapper>
      </Conatiner>
    </Layout>
  )
}

export const query = graphql`
  query {
    documentaryImg: file(
      relativePath: { eq: "portfolio_page/documentary.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    personalBrandingImg: file(
      relativePath: { eq: "portfolio_page/personal_branding.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
